<template>
  <div class="card">
    <div v-if="$slots.header" class="card-header">
      <slot name="header"></slot>
    </div>
    <div class="card-body">
      <div v-if="title">{{ title }}</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  components: {
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
      validator(value, props) {
        return ['primary', 'secondary', 'tertiary'].includes(value);
      },
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped></style>
