<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'WhatsApp'" :subtitle="$tc('app.number', 2)" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <CardComponent>
            <template #header>
              <ul class="actions top-right">
                <li>
                  <a href="javascript:void(0)" @click="fetch">
                    <span class="material-symbols-outlined title"> refresh</span>
                  </a>
                </li>
              </ul>
            </template>
            <TableComponent :items="numbers" :fields="fields" :busy="fetching">
            </TableComponent>
          </CardComponent>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from '../../components/PageHeader.vue';
import CardComponent from '../../components/CardComponent.vue';
import TableComponent from '../../components/TableComponent.vue';

export default {
  name: 'NumbersView',
  components: {
    PageHeader,
    CardComponent,
    TableComponent,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/whatsapp/jv/reports/history',
        2: '/whatsapp/jv/numbers',
      },
      fetching: false,
      numbers: [],
      fields: ['Número de telefone', 'Nome'],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetching = true;
      this.$store
        .dispatch('fetchWhatsappNumbers')
        .then((response) => {
          this.numbers = response.map((number) => ({
            phone_number: number.phone_number,
            name: number.name,
          }));
        })
        .finally(() => {
          this.fetching = false;
        });
    },
  },
};
</script>
